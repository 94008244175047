import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useAppContext } from "../contexts/AppContext";

const Overlay = () => {
  const {
    isShownOverlay,
    setIsShownOverlay,
    setIsShownRightAnswers,
    getRandomCorrectSticker,
    getRandomWrongSticker,
  } = useAppContext();

  const [img, setImg] = useState();

  useEffect(() => {
    if (!isShownOverlay) {
      return;
    }

    setImg(
      isShownOverlay === "correct"
        ? getRandomCorrectSticker()
        : getRandomWrongSticker(),
    );

    setTimeout(() => {
      if (isShownOverlay === "wrong") {
        setIsShownRightAnswers(true);

        setTimeout(() => {
          setIsShownRightAnswers(false);
        }, 2000);
      }

      setIsShownOverlay(false);

      setImg();
    }, 2000);
  }, [isShownOverlay]);

  const className = () => {
    if (!isShownOverlay) {
      return;
    }

    if (isShownOverlay === "correct") {
      return "animate__animated animate__tada";
    }

    return "animate__animated animate__jello";
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        // background: "rgba(0, 0, 0, 0.40)",
        zIndex: isShownOverlay ? "1000" : "-1",
        opacity: isShownOverlay ? "1" : "0",
        pointerEvents: "none",
        transition: "0.3s",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        className={className()}
        component={"img"}
        src={img}
        sx={{
          maxWidth: "25vw",
          "@media(max-width: 800px)": {
            maxWidth: "75vw",
          },
        }}
      />
    </Box>
  );
};

export default Overlay;
