import React from "react";
import { Box } from "@mui/material";
import { questions } from "../consts/consts";

const Dots = ({ activeIndex }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        "@media(max-width: 800px)": {
          gap: "16px",
        },
      }}
    >
      {questions.map((question, index) => (
        <Box
          key={`${question.question}${index}`}
          sx={{
            width: "8px",
            aspectRatio: "1 / 1",
            background: activeIndex >= index ? "#4AAC76" : "#000",
            borderRadius: "50%",
            "@media(max-width: 800px)": {
              width: "8px",
            },
          }}
        />
      ))}
    </Box>
  );
};

export default Dots;
