import React from "react";
import { Box } from "@mui/material";
import { letters, questions } from "../../consts/consts";
import { useAppContext } from "../../contexts/AppContext";
import useSound from "use-sound";
import clickSound from "../../assets/button_click.mp3";

const AnswerText = ({ answerIndex, answer, maxWidth, questionIndex }) => {
  const { data, setData, isShownRightAnswers } = useAppContext();

  const [playClick] = useSound(clickSound, {
    interrupt: true,
  });

  const isSingleAnswer = questions[questionIndex].correctAnswers.length === 1;
  const isCorrect =
    questions[questionIndex].correctAnswers.includes(answerIndex);
  const isDone = data.answers[questionIndex].done;
  const isMarked =
    data.answers[questionIndex].markedAnswers.includes(answerIndex);

  const backgroundColor = () => {
    if (isDone) {
      if (isCorrect) {
        return "#9BCDB1";
      } else {
        if (isMarked) {
          return "#D26A47";
        }
      }
    }

    if (isMarked) {
      return "#B7B7B7";
    }

    return "#FFFFFF";
  };

  const onClick = () => {
    if (isDone) {
      return;
    }

    if (isSingleAnswer) {
      playClick();

      return setData((prevState) => {
        return {
          ...prevState,
          answers: {
            ...prevState.answers,
            [questionIndex]: {
              ...prevState.answers[questionIndex],
              markedAnswers: [answerIndex],
            },
          },
        };
      });
    }

    if (isMarked) {
      playClick();

      return setData((prevState) => {
        return {
          ...prevState,
          answers: {
            ...prevState.answers,
            [questionIndex]: {
              ...prevState.answers[questionIndex],
              markedAnswers: prevState.answers[
                questionIndex
              ].markedAnswers.filter((i) => i !== answerIndex),
            },
          },
        };
      });
    }

    playClick();

    return setData((prevState) => {
      return {
        ...prevState,
        answers: {
          ...prevState.answers,
          [questionIndex]: {
            ...prevState.answers[questionIndex],
            markedAnswers: [
              ...prevState.answers[questionIndex].markedAnswers,
              answerIndex,
            ],
          },
        },
      };
    });
  };

  return (
    <Box
      sx={{
        backgroundColor,
        display: "flex",
        alignItems: "center",
        gap: "15px",
        borderRadius: "30px",
        padding: "18px 22px 18px 25px",
        cursor: "pointer",
        width: "100%",
        transition: "0.3s",
        position: "relative",
        maxWidth: "516px",
        "@media(max-width: 1500px)": {
          padding: "14px 22px 14px 25px",
        },
        "@media(max-width: 800px)": {
          gap: "26px",
        },
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          alignSelf: "start",
          fontSize: "40px",
        }}
      >
        {letters[answerIndex]}
      </Box>

      <Box
        className={"line-height-1-4"}
        sx={{
          width: maxWidth,
          fontSize: "20px",
          letterSpacing: "-0.22px",
          "@media(max-width: 1500px)": {
            fontSize: "18px",
          },
          "@media(max-width: 800px)": {
            maxWidth,
            width: "unset",
            fontSize: "17px",
          },
        }}
      >
        {answer.answer}
      </Box>

      {isCorrect && (
        <Box
          sx={{
            position: "absolute",
            // top: "50%",
            left: "50%",
            translate: "-50% 0",
            bottom: "-18px",
            background: "#de6523",
            borderRadius: "44px",
            fontSize: "18px",
            letterSpacing: "-0.22px",
            zIndex: "1",
            transition: "0.3s",
            color: "#fff",
            padding: "9px 21px",
            whiteSpace: "nowrap",
            opacity: isShownRightAnswers ? "1" : "0",
          }}
        >
          Správna odpoveď je táto
        </Box>
      )}
    </Box>
  );
};

export default AnswerText;
