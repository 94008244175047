import React from "react";
import { Box } from "@mui/material";
import { useAppContext } from "../contexts/AppContext";
import Button from "../components/Button";
import Lottie from "react-lottie";
import lottie11 from "../assets/update/lottie/lottie11.json";
import characterLottie from "../assets/update/lottie/EXPORT_CHARAKTER.json";

const Resume = ({ isActive }) => {
  const { goNextSlide } = useAppContext();

  return (
    <Box
      sx={{
        minHeight: "100lvh",
        background: "linear-gradient(180deg, #FF7F34 41.5%, #E76012 100%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "6vh",
        "@media(max-width: 800px)": {
          paddingTop: "25px",
        },
      }}
    >
      <Box
        className={"font font-60 medium"}
        sx={{
          marginBottom: "6vh",
          lineHeight: "1.3",
          letterSpacing: "-0.66px",
          "@media(max-width: 800px)": {
            marginBottom: "55px",
          },
        }}
      >
        Už zostávajú <br /> iba 3 otázky!
      </Box>

      <Box
        sx={{
          position: "relative",
          flex: "1",
          width: "100vw",
          overflow: "hidden",
          pointerEvents: "none",
          "& svg": {
            transform: "unset !important",
          },
        }}
      >
        <Lottie
          isPaused={!isActive}
          options={{
            loop: false,
            autoplay: false,
            animationData: characterLottie,
          }}
          width={"100%"}
          height={"75%"}
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            translate: "-50% -50%",
          }}
        />
      </Box>

      <Button
        type={"white"}
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "unset",
        }}
        sxMobile={{
          position: "absolute",
          left: "0",
          bottom: "0",
          width: "100%",
          height: "74px",
          maxWidth: "unset",
          fontSize: "27px",
        }}
        onClick={goNextSlide}
      >
        Pokračovať
      </Button>
    </Box>
  );
};

export default Resume;
