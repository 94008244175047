import { Box } from "@mui/material";
import React from "react";
import useSound from "use-sound";
import clickSound from "../assets/button_click.mp3";

const Button = ({
  children,
  disableSound,
  sx,
  sxHover,
  sxMobile,
  type,
  onClick,
  ...props
}) => {
  let color = "#fff";
  let background = "#f36f20";
  let border = "4px solid #e76012";
  let hoverBackground = "#e76012";
  let hoverBorderColor = "#ca4f08";

  if (type === "white") {
    color = "#4d4d4d";
    background = "#fff";
    border = "4px solid #e6e6e6";
    hoverBackground = "#fff";
    hoverBorderColor = "#e6e6e6";
  }

  const [playClick] = useSound(clickSound, {
    interrupt: true,
  });

  return (
    <Box
      sx={{
        color,
        background,
        border,
        minHeight: "48px",
        height: "8vh",
        minWidth: "150px",
        maxWidth: "340px",
        fontWeight: "500",
        letterSpacing: "0.34px",
        textAlign: "center",
        lineHeight: "16px",
        padding: "16px 24px",
        fontSize: "23px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "0.3s",
        cursor: "pointer",
        "&:hover": {
          background: hoverBackground,
          borderColor: hoverBorderColor,
          textDecoration: "underline",
          boxShadow: "0 12px 24px 0 rgba(0,0,0,.2)",
          ...sxHover,
        },
        "@media(max-width: 800px)": {
          height: "56px",
          fontSize: "16px",
          maxWidth: "240px",
          ...sxMobile,
        },
        ...sx,
      }}
      onClick={(e) => {
        if (!disableSound) {
          playClick();
        }

        onClick && onClick(e);
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default Button;
