import React from "react";
import SlickSlider from "react-slick";
import { Box } from "@mui/material";
import { useAppContext } from "../contexts/AppContext";

const Slider = ({ children }) => {
  const { sliderRef, setActiveSlide, setIsShownRightAnswers } = useAppContext();

  const settings = {
    dots: false,
    fade: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    draggable: false,
    touchMove: false,
    initialSlide: 0,
    // afterChange: (index) => setActiveSlide(index),
    beforeChange: (oldIndex, newIndex) => {
      setActiveSlide(newIndex);
      setIsShownRightAnswers(false);
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100dvh",
        "& .slick-slider": {
          userSelect: "unset",
          touchAction: "unset",
        },
        "& .slick-slider, & .slick-list": {
          width: "100%",
          height: "100%",
        },
        "& .slick-track": {
          height: "100%",
        },
        "& .slick-slide": {
          pointerEvents: "none",
          zIndex: "-1",
        },
        "& .slick-active": {
          pointerEvents: "unset",
          zIndex: "unset",
        },
        "& .slick-slide > div": {
          width: "100%",
          height: "100%",
          overflowY: "auto",
        },
      }}
    >
      <SlickSlider ref={sliderRef} {...settings}>
        {children}
      </SlickSlider>
    </Box>
  );
};

export default Slider;
