import React from "react";
import { Box } from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";
import { cashPerAnswer, questions } from "../../consts/consts";
import useMediaQuery from "@mui/material/useMediaQuery";
import useSound from "use-sound";
import clickSound from "../../assets/button_click.mp3";

const Button = ({ questionIndex, showExplanation }) => {
  const {
    data,
    setData,
    isShownOverlay,
    setIsShownOverlay,
    isShownRightAnswers,
    goNextSlide,
  } = useAppContext();

  const [playClick] = useSound(clickSound, {
    interrupt: true,
  });

  const isMobile = useMediaQuery("(max-width:800px)");

  const isDone = data.answers[questionIndex].done;
  const isMarked = data.answers[questionIndex].markedAnswers.length;
  const markedAnswers = data.answers[questionIndex].markedAnswers;
  const correctAnswers = questions[questionIndex].correctAnswers;

  const backgroundColor = () => {
    // if (isShownOverlay) {
    //   return "#EFEFEF";
    // }

    // if (isShownRightAnswers) {
    //   return "#EFEFEF";
    // }

    if (showExplanation) {
      return "#fff";
    }

    if (isDone) {
      return "#F36F20";
    }

    if (isMarked) {
      return "#F36F20";
    }

    return "#fff";
  };

  const border = () => {
    // if (isShownOverlay) {
    //   return "#EFEFEF";
    // }

    // if (isShownRightAnswers) {
    //   return "#EFEFEF";
    // }

    if (showExplanation) {
      return "4px solid #e6e6e6";
    }

    if (isDone) {
      return "4px solid #e76012";
    }

    if (isMarked) {
      return "4px solid #e76012";
    }

    return "4px solid #e6e6e6";
  };

  const isDisabled = () => {
    if (isShownOverlay) {
      return true;
    }

    if (isDone) {
      return false;
    }

    if (!markedAnswers.length) {
      return true;
    }

    return false;
  };

  const onClick = () => {
    if (isShownOverlay) {
      return;
    }

    // if (isShownRightAnswers) {
    //   return;
    // }

    if (isDone) {
      playClick();

      return goNextSlide();
    }

    if (!markedAnswers.length) {
      return;
    }

    playClick();

    setData((prevState) => {
      return {
        ...prevState,
        answers: {
          ...prevState.answers,
          [questionIndex]: {
            ...prevState.answers[questionIndex],
            done: true,
          },
        },
      };
    });

    let isCorrect = true;

    correctAnswers.forEach((correctAnswer) => {
      if (!markedAnswers.includes(correctAnswer)) {
        isCorrect = false;
      }
    });

    markedAnswers.forEach((markedAnswer) => {
      if (!correctAnswers.includes(markedAnswer)) {
        isCorrect = false;
      }
    });

    if (isCorrect) {
      setIsShownOverlay("correct");
    } else {
      setIsShownOverlay("wrong");
      setData((prevState) => {
        return {
          ...prevState,
          cash: prevState.cash - cashPerAnswer,
        };
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor,
        border,
        fontSize: "18px",
        fontWeight: "500",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 0",
        cursor: isDisabled() ? "not-allowed" : "pointer",
        transition: "0.3s",
        gap: "24px",
        minHeight: "63px",
        position: "fixed",
        bottom: "0",
        left: "0",
        width: "100%",
        zIndex: "101",
        "&:hover": {
          textDecoration: "underline",
          background:
            !showExplanation && (isDone || isMarked) ? "#e76012" : "#fff",
          borderColor:
            !showExplanation && (isDone || isMarked) ? "#ca4f08" : "#e6e6e6",
          boxShadow: "0 12px 24px 0 rgba(0,0,0,.2)",
        },
        "@media(max-width: 1500px)": {
          minHeight: "74px",
        },
        "@media(max-width: 800px)": {
          minHeight: "74px",
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
          justifyContent: "center",
          gap: "16px",
          height: "74px",
          fontSize: "27px",
        },
      }}
      onClick={onClick}
    >
      {!isDone && isMarked ? "Vyhodnotiť" : "Pokračovať"}
    </Box>
  );
};

export default Button;
