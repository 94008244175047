import { CssBaseline } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "./components/Slider";
import { questions } from "./consts/consts";
import Question from "./components/Question/Question";
import Overlay from "./components/Overlay";
import Intro from "./slides/Intro";
import Resume from "./slides/Resume";
import Rating from "./slides/Rating";
import Home from "./slides/Home";
import Login from "./slides/Login2";
import End from "./slides/End";
import { useAppContext } from "./contexts/AppContext";

function App() {
  const { activeSlide } = useAppContext();

  return (
    <>
      <CssBaseline />

      <Overlay />

      <Slider>
        <Home />

        <Intro />

        {/*1*/}
        <Question
          key={questions[0].id}
          isActive={activeSlide === 2}
          index={0}
          question={questions[0]}
        />

        <Question
          showExplanation
          key={`${questions[0].id}0`}
          isActive={activeSlide === 3}
          index={0}
          question={questions[0]}
        />

        {/*2*/}
        <Question
          key={questions[1].id}
          isActive={activeSlide === 4}
          index={1}
          question={questions[1]}
        />

        <Question
          showExplanation
          key={`${questions[1].id}0`}
          isActive={activeSlide === 5}
          index={1}
          question={questions[1]}
        />

        {/*3*/}
        <Question
          key={questions[2].id}
          isActive={activeSlide === 6}
          index={2}
          question={questions[2]}
        />

        <Question
          showExplanation
          key={`${questions[2].id}0`}
          isActive={activeSlide === 7}
          index={2}
          question={questions[2]}
        />

        {/*4*/}
        <Question
          key={questions[3].id}
          isActive={activeSlide === 8}
          index={3}
          question={questions[3]}
        />

        <Question
          showExplanation
          key={`${questions[3].id}0`}
          isActive={activeSlide === 9}
          index={3}
          question={questions[3]}
        />

        {/*5*/}
        <Question
          key={questions[4].id}
          isActive={activeSlide === 10}
          index={4}
          question={questions[4]}
        />

        <Question
          showExplanation
          key={`${questions[4].id}0`}
          isActive={activeSlide === 11}
          index={4}
          question={questions[4]}
        />

        {/*6*/}
        <Question
          key={questions[5].id}
          isActive={activeSlide === 12}
          index={5}
          question={questions[5]}
        />

        <Question
          showExplanation
          key={`${questions[5].id}0`}
          isActive={activeSlide === 13}
          index={5}
          question={questions[5]}
        />

        {/*7*/}
        <Question
          key={questions[6].id}
          isActive={activeSlide === 14}
          index={6}
          question={questions[6]}
        />

        <Question
          showExplanation
          key={`${questions[6].id}0`}
          isActive={activeSlide === 15}
          index={6}
          question={questions[6]}
        />

        {/*8*/}
        <Question
          key={questions[7].id}
          isActive={activeSlide === 16}
          index={7}
          question={questions[7]}
        />

        <Question
          showExplanation
          key={`${questions[7].id}0`}
          isActive={activeSlide === 17}
          index={7}
          question={questions[7]}
        />

        {/*9*/}
        <Question
          key={questions[8].id}
          isActive={activeSlide === 18}
          index={8}
          question={questions[8]}
        />

        <Question
          showExplanation
          key={`${questions[8].id}0`}
          isActive={activeSlide === 19}
          index={8}
          question={questions[8]}
        />

        {/*10*/}
        <Question
          key={questions[9].id}
          isActive={activeSlide === 20}
          index={9}
          question={questions[9]}
        />

        <Question
          showExplanation
          key={`${questions[9].id}0`}
          isActive={activeSlide === 21}
          index={9}
          question={questions[9]}
        />

        <Resume isActive={activeSlide === 22} />

        <Question
          key={questions[10].id}
          isActive={activeSlide === 23}
          index={10}
          question={questions[10]}
        />

        <Question
          showExplanation
          key={`${questions[10].id}0`}
          isActive={activeSlide === 24}
          index={10}
          question={questions[10]}
        />

        <Question
          key={questions[11].id}
          isActive={activeSlide === 25}
          index={11}
          question={questions[11]}
        />

        <Question
          showExplanation
          key={`${questions[11].id}0`}
          isActive={activeSlide === 26}
          index={11}
          question={questions[11]}
        />

        <Question
          key={questions[12].id}
          isActive={activeSlide === 27}
          index={12}
          question={questions[12]}
        />

        <Question
          showExplanation
          key={`${questions[12].id}0`}
          isActive={activeSlide === 28}
          index={12}
          question={questions[12]}
        />

        <Rating />

        <Login />

        <End />
      </Slider>
    </>
  );
}

export default App;
