import React from "react";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppContext } from "../../contexts/AppContext";
import { questions } from "../../consts/consts";

const AnswerImage = ({ questionIndex, question }) => {
  if (question.answersImages.length === 5) {
    return <Answer6Images questionIndex={questionIndex} question={question} />;
  }

  return <Answer4Images questionIndex={questionIndex} question={question} />;
};

const Answer6Images = ({ questionIndex, question }) => {
  const maxWidth600 = useMediaQuery("(max-width:600px)");
  const maxWidth1000 = useMediaQuery("(max-width:1000px)");
  const maxWidth1400 = useMediaQuery("(max-width:1400px)");

  const getColumns = () => {
    if (maxWidth600) {
      return [
        [
          question.answersImages[0],
          question.answersImages[1],
          question.answersImages[2],
          question.answersImages[3],
          question.answersImages[4],
        ],
      ];
    }

    if (maxWidth1000) {
      return [
        [
          question.answersImages[0],
          question.answersImages[1],
          question.answersImages[2],
        ],
        [question.answersImages[3], question.answersImages[4]],
      ];
    }

    if (maxWidth1400) {
      return [
        [question.answersImages[0], question.answersImages[1]],
        [question.answersImages[2], question.answersImages[3]],
        [question.answersImages[4]],
      ];
    }

    return [
      [question.answersImages[0]],
      [question.answersImages[1]],
      [question.answersImages[2]],
      [question.answersImages[3]],
      [question.answersImages[4]],
    ];
  };

  const columns = getColumns();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "11px",
      }}
    >
      {columns.map((column, columnIndex) => (
        <Box
          key={columnIndex}
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            gap: "11px",
          }}
        >
          {column.map((img) => {
            return (
              <Img
                key={img.answer}
                src={img.answer}
                answerIndex={img.index}
                questionIndex={questionIndex}
              />
            );
          })}
        </Box>
      ))}
    </Box>
  );
};

const Answer4Images = ({ questionIndex, question }) => {
  const maxWidth600 = useMediaQuery("(max-width:600px)");
  const maxWidth1000 = useMediaQuery("(max-width:1000px)");

  const getColumns = () => {
    if (maxWidth600) {
      return [
        [
          question.answersImages[0],
          question.answersImages[1],
          question.answersImages[2],
          question.answersImages[3],
        ],
      ];
    }

    if (maxWidth1000) {
      return [
        [question.answersImages[0], question.answersImages[1]],
        [question.answersImages[2], question.answersImages[3]],
      ];
    }

    return [
      [question.answersImages[0]],
      [question.answersImages[1]],
      [question.answersImages[2]],
      [question.answersImages[3]],
    ];
  };

  const columns = getColumns();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "11px",
      }}
    >
      {columns.map((column, columnIndex) => (
        <Box
          key={columnIndex}
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            gap: "11px",
          }}
        >
          {column.map((img) => {
            return (
              <Img
                key={img.answer}
                src={img.answer}
                answerIndex={img.index}
                questionIndex={questionIndex}
              />
            );
          })}
        </Box>
      ))}
    </Box>
  );
};

const Img = ({ questionIndex, answerIndex, src, sx }) => {
  const { data, setData, isShownRightAnswers } = useAppContext();

  const isDone = data.answers[questionIndex].done;
  const isMarked =
    data.answers[questionIndex].markedAnswers.includes(answerIndex);
  const isCorrect =
    questions[questionIndex].correctAnswers.includes(answerIndex);

  const background = () => {
    if (isDone) {
      if (isCorrect) {
        return "linear-gradient(0deg, #4AAC7680 0%, #4AAC7680 100%)";
      } else {
        if (isMarked) {
          return "linear-gradient(0deg, #D26A47 0%, #D26A47 100%)";
        }
      }
    }

    if (isMarked) {
      return "linear-gradient(0deg, #B7B7B7 0%, #B7B7B7 100%)";
    }

    return "";
  };

  const onClick = () => {
    if (isDone) {
      return;
    }

    if (isMarked) {
      return setData((prevState) => {
        return {
          ...prevState,
          answers: {
            ...prevState.answers,
            [questionIndex]: {
              ...prevState.answers[questionIndex],
              markedAnswers: prevState.answers[
                questionIndex
              ].markedAnswers.filter((i) => i !== answerIndex),
            },
          },
        };
      });
    }

    return setData((prevState) => {
      return {
        ...prevState,
        answers: {
          ...prevState.answers,
          [questionIndex]: {
            ...prevState.answers[questionIndex],
            markedAnswers: [
              ...prevState.answers[questionIndex].markedAnswers,
              answerIndex,
            ],
          },
        },
      };
    });
  };

  return (
    <Box
      sx={{
        position: "relative",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Box
        component={"img"}
        src={src}
        sx={{
          borderRadius: "44px",
          width: "100%",
          ...sx,
        }}
      />

      <Box
        sx={{
          background,
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          borderRadius: "44px",
          transition: "0.3s",
          mixBlendMode: "multiply",
        }}
      />

      {isCorrect && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            translate: "-50% -50%",
            background: "#FF7F34",
            borderRadius: "44px",
            fontSize: "20px",
            letterSpacing: "-0.22px",
            zIndex: "1",
            transition: "0.3s",
            color: "#fff",
            padding: "14px 2.4vw",
            whiteSpace: "nowrap",
            opacity: isShownRightAnswers ? "1" : "0",
          }}
        >
          Správna odpoveď je táto
        </Box>
      )}
    </Box>
  );
};

export default AnswerImage;
