import React from "react";
import Lottie from "react-lottie";
import { Box } from "@mui/material";
import Dots from "../Dots";
import AnswerText from "./AnswerText";
import Button from "./Button";
import AnswerImage from "./AnswerImage";
import useMediaQuery from "@mui/material/useMediaQuery";
import explanationImg from "../../assets/update/explanation.svg";
import CashLottie from "./CashLottie";
import { isSafari } from "react-device-detect";

const Question = ({ index, isActive, question, showExplanation }) => {
  const is3Answers = question.answers?.length === 3;
  const is4Answers = question.answers?.length === 4;

  const isMobile = useMediaQuery("(max-width:800px)");

  const hideContent = showExplanation && isMobile;

  if (!isActive && isSafari && isMobile) {
    return null;
  }

  return (
    <Box
      sx={{
        minHeight: "100lvh",
        background: "#EDEDED",
        display: "flex",
        flexDirection: "column",
        paddingTop: "23px",
        overflow: "hidden",
        "@media(max-width: 1500px)": {
          paddingTop: "16px",
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr",
          alignItems: "center",
          padding: "0 4vw",
          "@media(max-width: 800px)": {
            display: "none",
          },
        }}
      >
        <Box
          className={"font font-35 medium"}
          sx={{
            fontWeight: "600",
          }}
        >
          Otázka č.{index + 1}
        </Box>

        <Dots activeIndex={index} />

        <CashLottie />
      </Box>

      <Box
        sx={{
          padding: "0 24px",
          "@media(min-width: 801px)": {
            display: "none",
          },
        }}
      >
        <CashLottie />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "0px",
          }}
        >
          <Box
            sx={{
              fontSize: "17px",
              marginBottom: "12px",
            }}
          >
            {showExplanation ? "Vyhodnotenie" : `Otázka č.${index + 1}`}
          </Box>

          <Dots activeIndex={index} />
        </Box>
      </Box>

      {!hideContent && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "1",
            padding: "0 4vw 70px 4vw",
            marginBottom: "5vh",
            gap: "3vw",
            "@media(max-width: 800px)": {
              gap: "unset",
              flex: "unset",
              padding: "0 28px 104px 28px",
              marginBottom: "5vh",
              marginTop: "27px",
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5vh",
              flex: "1",
            }}
          >
            <Box
              className={"font font-30 medium line-height-1-4"}
              sx={{
                fontWeight: "600",
                "@media(max-width: 1500px)": {
                  fontSize: "22px",
                },
                "@media(max-width: 800px)": {
                  "& br": {
                    display: "none",
                  },
                },
              }}
            >
              {question.question}
            </Box>

            {question.img && (
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  justifyContent: "center",
                  "@media(min-width: 801px)": {
                    display: "none",
                  },
                }}
              >
                <Box
                  component={"img"}
                  src={question.img}
                  sx={{
                    maxHeight: question.isFullSize ? undefined : "30vh",
                    maxWidth: "65vw",
                  }}
                />
              </Box>
            )}

            {question.lottie && (
              <Box
                sx={{
                  position: "relative",
                  left: index === 5 ? "-5%" : undefined,
                  flex: "1",
                  display: "flex",
                  justifyContent: "center",
                  "@media(min-width: 801px)": {
                    display: "none",
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    flex: "1",
                    height: "57vh",
                    maxHeight: question.isFullSize ? undefined : "30vh",
                    pointerEvents: "none",
                    "& svg": {
                      transform: "unset !important",
                    },
                  }}
                >
                  <Lottie
                    isPaused={isSafari && isMobile ? undefined : !isActive}
                    options={{
                      loop: question.loop,
                      autoplay: isSafari && isMobile ? true : question.loop,
                      animationData: question.lottie,
                    }}
                    width={question.isFullSize ? "300%" : "160%"}
                    height={question.isFullSize ? "200%" : "160%"}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      translate: "-50% -50%",
                    }}
                  />
                </Box>
              </Box>
            )}

            {is3Answers && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "11px",
                  alignItems: "start",
                  "@media(max-width: 800px)": {
                    gap: "21px",
                  },
                }}
              >
                {question.answers?.map((answer, answerIndex) => (
                  <AnswerText
                    key={answer.id}
                    answerIndex={answerIndex}
                    answer={answer}
                    maxWidth={"495px"}
                    questionIndex={index}
                  />
                ))}
              </Box>
            )}

            {is4Answers && (
              <Box
                sx={{
                  display: "flex",
                  gap: "11px",
                  "@media(max-width: 800px)": {
                    gap: "21px",
                    flexDirection: "column",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "11px",
                    alignItems: "start",
                    "@media(max-width: 800px)": {
                      gap: "21px",
                    },
                  }}
                >
                  {question.answers?.slice(0, 2)?.map((answer, answerIndex) => (
                    <AnswerText
                      key={answer.id}
                      answerIndex={answerIndex}
                      answer={answer}
                      maxWidth={"285px"}
                      questionIndex={index}
                    />
                  ))}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "11px",
                    alignItems: "start",
                    "@media(max-width: 800px)": {
                      gap: "21px",
                    },
                  }}
                >
                  {question.answers?.slice(2, 4)?.map((answer, answerIndex) => (
                    <AnswerText
                      key={answer.id}
                      answerIndex={answerIndex + 2}
                      answer={answer}
                      maxWidth={"285px"}
                      questionIndex={index}
                    />
                  ))}
                </Box>
              </Box>
            )}

            {Boolean(question.answersImages?.length) && (
              <AnswerImage questionIndex={index} question={question} />
            )}
          </Box>

          {question.img && (
            <Box
              sx={{
                flex: "1",
                display: "flex",
                justifyContent: "center",
                "@media(max-width: 1000px)": {
                  display: "none",
                },
              }}
            >
              <Box>
                <Box
                  component={"img"}
                  src={question.img}
                  sx={{
                    maxHeight: "63vh",
                    "@media(max-width: 1500px)": {
                      maxHeight: "55vh",
                    },
                  }}
                />
              </Box>
            </Box>
          )}

          {question.lottie && (
            <Box
              sx={{
                flex: "1",
                display: "flex",
                justifyContent: "center",
                "@media(max-width: 1000px)": {
                  display: "none",
                },
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  flex: "1",
                  height: "75vh",
                  overflow: "hidden",
                  pointerEvents: "none",
                  "& svg": {
                    transform: "unset !important",
                  },
                }}
              >
                <Lottie
                  isPaused={isSafari && isMobile ? undefined : !isActive}
                  options={{
                    loop: question.loop,
                    autoplay: isSafari && isMobile ? true : question.loop,
                    animationData: question.lottie,
                  }}
                  width={"160%"}
                  height={"160%"}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    translate: "-50% -50%",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}

      {showExplanation && !isMobile && (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "#D9D9D999",
            zIndex: "100",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none",
          }}
        >
          <Box
            sx={{
              width: "630px",
              boxShadow: "0px 4px 24px 0px #00000040",
              borderRadius: "30px",
              position: "relative",
              top: "-4%",
            }}
          >
            <Box
              sx={{
                borderRadius: "30px 30px 0 0",
                padding: "3vh 0",
                background: "#4AAC76",
                fontWeight: "600",
                color: "#fff",
                fontSize: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Vyhodnotenie
            </Box>

            <Box
              sx={{
                borderRadius: "0 0 30px 30px",
                background: "#ededed",
                padding: "6vh 0 10vh 0",
              }}
            >
              <Box
                sx={{
                  width: "405px",
                  margin: "0 auto",
                  position: "relative",
                }}
              >
                <Box
                  className={"font font-24 line-height-1-3"}
                  sx={{
                    background: "#9fcbb1",
                    padding: "24px 15px 24px 30px",
                    borderRadius: "30px",
                  }}
                >
                  {question.explanationText}
                </Box>

                <Box
                  component={"img"}
                  src={explanationImg}
                  sx={{
                    height: "60px",
                    margin: "0 auto",
                    position: "relative",
                    top: "-4px",
                    left: "-22px",
                  }}
                />

                <Box
                  component={"img"}
                  src={question.explanationImg}
                  sx={{
                    position: "absolute",
                    bottom: question.explanationOffset ? "-60px" : "-70px",
                    left: question.explanationOffset ? "0" : "40px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {showExplanation && isMobile && (
        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
            position: "relative",
            marginTop: "16px",
            padding: "0 30px 200px 30px",
          }}
        >
          <Box
            className={"line-height-1-4"}
            sx={{
              background: "#9fcbb1",
              padding: "16px 15px 24px 20px",
              borderRadius: "30px",
              fontSize: "17px",
            }}
          >
            {question.explanationText}
          </Box>

          <Box
            component={"img"}
            src={explanationImg}
            sx={{
              height: "60px",
              margin: "0 auto",
              position: "relative",
              top: "-4px",
              left: "-22px",
            }}
          />

          <Box
            component={"img"}
            src={question.explanationImg}
            sx={{
              width: question.explanationOffset ? "83px" : "56px",
              position: "absolute",
              bottom: question.explanationOffset ? "140px" : "140px",
              left: question.explanationOffset ? "30px" : "50px",
            }}
          />
        </Box>
      )}

      <Button questionIndex={index} showExplanation={showExplanation} />
    </Box>
  );
};

export default Question;
