import React, { useState } from "react";
import { Box } from "@mui/material";
import { getHackerEmotionLottie } from "../../consts/consts";
import { useAppContext } from "../../contexts/AppContext";
import Lottie from "react-lottie";
import useMediaQuery from "@mui/material/useMediaQuery";

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const Cash = () => {
  const { data } = useAppContext();

  const [cash, setCash] = useState(data.cash);

  const cashString = String(cash);
  const finalNumber = data.cash;
  const totalTime = 1000;

  const startCounter = () => {
    const numberPerTick = finalNumber / totalTime;
    const numberPerTickRandomized = randomIntFromInterval(
      numberPerTick,
      numberPerTick * 1.001,
    );

    setCash((prevState) => {
      const nextValue = prevState - numberPerTickRandomized;

      if (finalNumber >= nextValue) {
        return finalNumber;
      }

      return nextValue;
    });
  };

  if (cash !== data.cash) {
    requestAnimationFrame(startCounter);
  }

  const isMobile = useMediaQuery("(max-width:800px)");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        gap: "10px",
        "@media(max-width: 800px)": {
          justifyContent: "space-between",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: isMobile ? "70px" : "90px",
          height: isMobile ? "60px" : "76px",
        }}
      >
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: getHackerEmotionLottie(cash, "orange"),
          }}
          width={isMobile ? "250px" : "336px"}
          height={isMobile ? "140px" : "189px"}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            translate: "-50% -50%",
          }}
        />
      </Box>

      <Box
        sx={{
          color: "#fff",
          textAlign: "right",
        }}
      >
        <Box
          sx={{
            fontSize: "22px",
            letterSpacing: "-0.2px",
            marginBottom: "12px",
            fontWeight: "500",
            "@media(max-width: 800px)": {
              fontSize: "15px",
            },
          }}
        >
          Váš budget:
        </Box>

        <Box
          className={"medium"}
          sx={{
            fontSize: "35px",
            letterSpacing: "-0.4px",
            minWidth: "140px",
            fontWeight: "600",
            "@media(max-width: 800px)": {
              fontSize: "25px",
            },
          }}
        >
          {cashString[0]} {cashString.slice(1, cashString.length)} €
        </Box>
      </Box>
    </Box>
  );
};

export default Cash;
