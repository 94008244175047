import React from "react";
import { Box } from "@mui/material";
import { useAppContext } from "../contexts/AppContext";
import Button from "../components/Button";
import Lottie from "react-lottie";
import lottie8 from "../assets/update/lottie/lottie8.json";

const Intro = () => {
  const { goNextSlide } = useAppContext();

  return (
    <Box
      sx={{
        minHeight: "100lvh",
        display: "flex",
        flexDirection: "column",
        padding: "10vh 12vw 8vh 5vw",
        background: "#EDEDED",
        "@media(max-width: 800px)": {
          padding: "50px 30px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: "1",
          "@media(max-width: 800px)": {
            flex: "unset",
            marginBottom: "55px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            "@media(max-width: 800px)": {
              flex: "1",
            },
          }}
        >
          <Box
            className={"font font-57 medium line-height-1-3"}
            sx={{
              marginBottom: "9vh",
              marginLeft: "10px",
              "@media(max-width: 800px)": {
                marginBottom: "38px",
                marginLeft: "0",
              },
            }}
          >
            Vitajte v hre <br /> Nenechajte sa <br /> nachytať!
          </Box>

          <Box
            className={"font font-30 medium line-height-1-4"}
            sx={{
              marginBottom: "3vh",
              maxWidth: "650px",
              marginLeft: "10px",
              flex: "1",
              "@media(max-width: 800px)": {
                marginBottom: "38px",
                marginLeft: "0",
              },
            }}
          >
            V hre máte budget <b>8 000 EUR,</b> ktorý si musíte strážiť pred
            útokmi hackera. Dajte si pozor, ak vyberiete nesprávnu odpoveď,
            prídete o časť vašich peňazí. Zahrajte sa a zistite ako ste na tom.
          </Box>

          <Button
            type={"white"}
            sxMobile={{
              position: "absolute",
              left: "0",
              bottom: "0",
              width: "100%",
              height: "74px",
              maxWidth: "unset",
              fontSize: "27px",
            }}
            onClick={goNextSlide}
          >
            Spustiť kvíz
          </Button>
        </Box>

        <Box
          sx={{
            position: "relative",
            flex: "1",
            height: "70vh",
            overflow: "hidden",
            "& svg": {
              transform: "unset !important",
            },
            "@media(max-width: 800px)": {
              display: "none",
            },
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: lottie8,
            }}
            width={"160%"}
            height={"160%"}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              translate: "-50% -50%",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Intro;
