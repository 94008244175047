import React, { createContext, useContext, useRef, useState } from "react";
import { annualCash } from "../consts/consts";
import correctSticker1 from "../assets/correctSticker1.png";
import correctSticker2 from "../assets/correctSticker2.png";
import correctSticker3 from "../assets/correctSticker3.png";
import wrongSticker1 from "../assets/wrongSticker1.png";
import wrongSticker2 from "../assets/wrongSticker2.png";

const AppContext = createContext({});

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const AppContextProvider = ({ children }) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    cash: annualCash,
    answers: {
      0: {
        done: false,
        markedAnswers: [],
      },
      1: {
        done: false,
        markedAnswers: [],
      },
      2: {
        done: false,
        markedAnswers: [],
      },
      3: {
        done: false,
        markedAnswers: [],
      },
      4: {
        done: false,
        markedAnswers: [],
      },
      5: {
        done: false,
        markedAnswers: [],
      },
      6: {
        done: false,
        markedAnswers: [],
      },
      7: {
        done: false,
        markedAnswers: [],
      },
      8: {
        done: false,
        markedAnswers: [],
      },
      9: {
        done: false,
        markedAnswers: [],
      },
      10: {
        done: false,
        markedAnswers: [],
      },
      11: {
        done: false,
        markedAnswers: [],
      },
      12: {
        done: false,
        markedAnswers: [],
      },
    },
  });
  const [isShownOverlay, setIsShownOverlay] = useState(false);
  const [isShownRightAnswers, setIsShownRightAnswers] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const sliderRef = useRef();
  const isSavedToDb = useRef(false);

  const correctStickerIndex = useRef(0);
  const wrongStickerIndex = useRef(0);

  const goNextSlide = () => {
    if (!sliderRef.current) {
      return;
    }

    sliderRef.current.slickNext();
  };

  const goToSlide = (slideIndex) => {
    if (!sliderRef.current) {
      return;
    }

    sliderRef.current.slickGoTo(slideIndex);
  };

  // useEffect(() => {
  //   localStorage.setItem("vubQuizData", JSON.stringify(data));
  // }, [data]);

  const getRandomCorrectSticker = () => {
    const stickers = [correctSticker1, correctSticker2, correctSticker3];

    const currentIndex = correctStickerIndex.current;

    if (currentIndex + 1 > 2) {
      correctStickerIndex.current = 0;
    } else {
      correctStickerIndex.current = correctStickerIndex.current + 1;
    }

    return stickers[currentIndex] || stickers[0];
  };

  const getRandomWrongSticker = () => {
    const stickers = [wrongSticker1, wrongSticker2];

    const currentIndex = wrongStickerIndex.current;

    if (currentIndex + 1 > 1) {
      wrongStickerIndex.current = 0;
    } else {
      wrongStickerIndex.current = wrongStickerIndex.current + 1;
    }

    return stickers[currentIndex] || stickers[0];
  };

  return (
    <AppContext.Provider
      value={{
        data,
        setData,
        isShownOverlay,
        setIsShownOverlay,
        isShownRightAnswers,
        setIsShownRightAnswers,
        activeSlide,
        setActiveSlide,
        sliderRef,
        isSavedToDb,
        goNextSlide,
        goToSlide,
        getRandomCorrectSticker,
        getRandomWrongSticker,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { AppContext, useAppContext, AppContextProvider as default };
